


























































































import {Component} from 'vue-property-decorator';
import {dispatchDeletePanel, dispatchGetPanelsForList} from '@/store/crud/actions';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';

@Component({components: {ConfirmationModal}})
export default class AdminPanel extends AppComponent {
  public headers = [
    {
      text: 'Name',
      sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: 'description',
      sortable: true,
      value: 'description',
      align: 'left',
    },
    {
      text: 'Quest test code',
      sortable: true,
      value: 'questTestCode',
      align: 'left',
    },
    {
      text: 'Mode of acquisition',
      sortable: true,
      value: 'modeOfAcquisition',
      align: 'left',
    },
    {
      text: 'Quest price',
      sortable: true,
      value: 'questPrice',
      align: 'left',
    },
    {
      text: 'Actions',
      value: 'id',
      align: 'center',
    },
  ];

  public search = '';
  public fetchingPanelsData = false;

  /**
   * mounted hook calls async fetch data
   */
  public mounted() {
    this.fetchData();
  }

  /**
   * fetches data asyncrhonously from the server
   * and displays loading spinner
   */
  public async fetchData() {
    this.fetchingPanelsData = true;
    await this.$nextTick();
    await dispatchGetPanelsForList(this.$store);
    this.fetchingPanelsData = false;
  }

  /**
   * Delete a panel and refresh the screen
   */
  public async deletePanel(panelId: string) {
    await dispatchDeletePanel(this.$store, {panelId});
    await this.fetchData();
  }
}
